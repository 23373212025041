

import { computed, ref } from 'vue';

// components
import { IonPage, IonContent, IonGrid, IonCol, IonRow, IonSpinner, IonButtons, IonButton, IonIcon, IonBackButton,
        IonItem, IonLabel, IonBadge, IonThumbnail, IonList, IonListHeader, } from '@ionic/vue';
import Slides from '@/components/slides/Slides.vue';
import PaymentInfo from '@/components/order/PaymentInfo.vue';
import OrderInfo from '@/components/order/OrderInfo.vue';

// icons
import { location, arrowBackOutline } from 'ionicons/icons';

// services
import { useRoute } from 'vue-router';
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'OrderDetailPage',
  components: { IonContent, IonPage, IonGrid, IonCol, IonRow, IonSpinner, IonButtons, IonButton, IonIcon, IonBackButton,
                IonItem, IonLabel, IonBadge, IonThumbnail, IonList, IonListHeader,
                Slides, PaymentInfo, OrderInfo, },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const { id, } = route.params; // order ID

    const { getOrderStatusColor, formatDateString, openModal, ORDER_STATUSES, } = utils();

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingAppUserData);
    const order = computed(() => store.getters.getOrderById(id));
  
    return {
      // icons
      location, arrowBackOutline,
      
      // variables
      loading, order,
      ORDER_STATUSES,

      // methods
      t, getOrderStatusColor, formatDateString,
    }
  },
}
